import React, { useRef, useState } from "react";
import { TimelineInterface } from "../../../views/app-views/order-details/OrderDetails";
import OrderService from "../../../service/OrderService";
import {
  Col,
  Empty,
  Input,
  InputNumber,
  InputRef,
  Modal,
  Radio,
  Row,
  Slider,
  Spin,
} from "antd";
import TimelineOrder from "../../../views/app-views/order-details/TimelineOrder";
import "./style.css";

const { Search } = Input;
const CheckOrderTab = () => {
  const [searchResult, setSerachResult] = useState<TimelineInterface[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const searchRef = useRef<InputRef>(null);

  const onSearch = async () => {
    console.log("s");

    setLoading(true);
    if (!!searchRef?.current?.input?.value) {
      const response = await OrderService.fetchTimelineByBarCode(
        searchRef?.current?.input?.value
      );
      setNotFound(response.data.length === 0);

      setSerachResult(response.data);
    }
    setModalShow(true);
    setLoading(false);
  };

  return (
    <div className="checkOrderTab " id="track">
      <div className="search_code_wrapper wow bounceIn">
        <h2>Сәлемдемені қадағалау</h2>
        <div className="search_code_container">
          <Input
            className="search_code "
            ref={searchRef}
            placeholder="Трек-кодты енгізіңіз"
          />
          <button className="search_code_btn" onClick={() => onSearch()}>
            Тексеру
          </button>
        </div>
      </div>

      {/* <Search
        className="search_code"
        placeholder="Введите штрих-код вашего заказа"
        enterButton="Поиск"
        size="large"
        loading={false}
        onSearch={onSearch}
      /> */}
      {/* <Row align="middle">
        <Col span={10}>
          {" "}
          <Radio.Group
          //    onChange={onChange} value={value}
          >
            <Radio value={1}>Алматы (3.8$)</Radio>
            <Radio value={2}>Регион (4.5$)</Radio>
          </Radio.Group>
        </Col>
        <Col span={10}>
          <Slider
            min={1}
            max={50}
            onChange={onChange}
            value={typeof inputValue === "number" ? inputValue : 0}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={1}
            max={50}
            style={{ margin: "0 16px" }}
            value={inputValue}
            onChange={onChange}
          />
          $
        </Col>
      </Row> */}
      <Modal
        centered
        // width={"min-content"}
        closable={true}
        title=""
        open={modalShow}
        footer={null}
        onCancel={() => setModalShow(false)}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div className="searchResult__wrapper">
            {searchResult.length > 0 ? (
              <TimelineOrder timeline={searchResult} message_ru={true} />
            ) : (
              <Empty description={<span>Базаға тіркелмеген</span>} />
            )}
            {/* {notFound && <Empty description={<span>Заказ не найден</span>} />} */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CheckOrderTab;
