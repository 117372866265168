import fetch from "../auth/FetchInterceptor";

export interface qrCodeDataInterface {
  qrId: string;
  warehouse: string;
}

export enum PLAN_TYPE {
  EXPRESS_PLAN = "EXPRESS_PLAN",
  TRAIN_PLAN = "TRAIN_PLAN",
  PLANE_PLAN = "PLANE_PLAN",
  STANDARD_PLAN = "STANDARD_PLAN",
}

export enum QrCodeType {
  CHINA_SIDE = "CHINA_SIDE",
  KAZAKHSTAN_SIDE = "KAZAKHSTAN_SIDE",
}

export enum PLAN_TYPE_COLOR {
  EXPRESS_PLAN = "red",
  TRAIN_PLAN = "lime",
  PLANE_PLAN = "blue",
  STANDARD_PLAN = "purple",
}

export interface IReceiveOrders {
  barCodes: string[];
  iin: string;
  fee: number;
  weight: number;
  currency: number;
  price: number;
  isFilial: boolean;
}

export const PlanTypeTextData = [
  {
    key: PLAN_TYPE.EXPRESS_PLAN,
    text_cn: "快运",
    text_ru: "Экспресс",
  },
  {
    key: PLAN_TYPE.TRAIN_PLAN,
    text_cn: "火车运",
    text_ru: "Поезд",
  },
  {
    key: PLAN_TYPE.PLANE_PLAN,
    text_cn: "空运",
    text_ru: "Самолёт",
  },
  {
    key: PLAN_TYPE.STANDARD_PLAN,
    text_cn: "标准运",
    text_ru: "Стандард",
  },
];
interface ISearchBarCode {
  barCode: string;
}
const OrderService = {
  deleteOrder: function (data: any) {
    return fetch({
      url: "/orders/" + data,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },
  scanAndCreateOrder: function (data: any) {
    return fetch({
      url: "/orders",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },

  scanAndUpdateOrder: function (data: any) {
    return fetch({
      url: "/sendkz/change-orderGroup-by-barCode",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },

  sendKzInner: function (data: { orderGroup: string }) {
    return fetch({
      url: "/sendkz/send-update-timeline",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },

  scanAndUpdateOrderList: function (data: {
    orderGroup: string;
    barCodes: string[];
  }) {
    return fetch({
      url: "/sendkz/change-orderGroup-by-barCode-list",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },

  recieveKzInner: function (data: { orderGroup: string }) {
    return fetch({
      url: "/sendkz/receive-update-timeline",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },
  globalSearch: function (barCode: string) {
    return fetch({
      url: `/global-search/${barCode}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  fetchOrder: function (orderGroup: string, page: number, limit = 5) {
    return fetch({
      url: `/orders-filter?orderGroup=${orderGroup}&page=${page}&limit=${limit}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  delete: function (order: string) {
    return fetch({
      url: `/orders/${order}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },

  fetchQrcode: function (order: string) {
    return fetch({
      url: `/order-qrCodes?orderGroup=${order}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
  fetchTimeline: function (order: string) {
    return fetch({
      url: `/order-timeline?order=${order}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
  fetchTimelineByBarCode: function (barcode: string) {
    return fetch({
      url: `/order-timeline?barcode=${barcode}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  createTimeLineAfterScan: function (data: qrCodeDataInterface) {
    return fetch({
      url: `/create-timeline-afterScan`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  receive: function (data: IReceiveOrders) {
    return fetch({
      url: `/receive-order`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },
  search: function (data: ISearchBarCode) {
    return fetch({
      url: `/search-order`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  clientPreCheck: function (data: { barCode: string }) {
    return fetch({
      url: `/client-order-precheck`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  readyPickupBarcode: function (data: { barCodes: string[] }) {
    return fetch({
      url: `/order-status-update-pickup`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  importBarcodeExcel: function (data: any, orderGroup: string) {
    return fetch({
      url: `/import-excel/${orderGroup}`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": `multipart/form-data`, // Set the content type to handle form data
      },
    });
  },

  importBarcodeList: function (data?: any) {
    return fetch({
      url: `/import-barcode-list`,
      method: "post",
      data: data,
    });
  },

  getOrderByPhone: function (data?: any) {
    return fetch({
      url: `/get-orders-by-phone`,
      method: "post",
      data: data,
    });
  },
};

export default OrderService;
