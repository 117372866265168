import { PLAN_TYPE, PLAN_TYPE_COLOR } from "../service/OrderService";
import { CargoType, CargoTypeColor } from "../service/PricelistService";
import { CABINET_ORDER_STATAUS } from "../views/app-views/cabinet/ICabinet";
// import { CABINET_ORDER_STATAUS } from "../views/app-views/cabinet/ICabinet";
import {
  CustomerBarCodeStatus,
  CustomerBarCodeStatusColor,
  OrderStatus,
  OrderStatusColor,
} from "../views/app-views/home/statusOrder";

export function getCargoTypeEnumText(text: CargoType) {
  switch (text) {
    case CargoType.BIG_CARGO:
      return { text: "大百货", color: CargoTypeColor.BIG_CARGO };
    case CargoType.SMALL_CARGO:
      return { text: "小百货", color: CargoTypeColor.SMALL_CARGO };
    case CargoType.CLOTH:
      return { text: "衣服", color: CargoTypeColor.CLOTH };
    case CargoType.TOY:
      return { text: "玩具", color: CargoTypeColor.TOY };

    default:
      break;
  }
}

export function getPlanTypeEnumText(text: PLAN_TYPE) {
  switch (text) {
    case PLAN_TYPE.EXPRESS_PLAN:
      return { text: "快运", color: PLAN_TYPE_COLOR.EXPRESS_PLAN };
    case PLAN_TYPE.PLANE_PLAN:
      return { text: "空运", color: PLAN_TYPE_COLOR.PLANE_PLAN };
    case PLAN_TYPE.STANDARD_PLAN:
      return { text: "标准运", color: PLAN_TYPE_COLOR.STANDARD_PLAN };
    case PLAN_TYPE.TRAIN_PLAN:
      return { text: "火车运", color: PLAN_TYPE_COLOR.TRAIN_PLAN };

    default:
      break;
  }
}

export function getOrderStatusEnumText(text: OrderStatus) {
  switch (text) {
    case OrderStatus.SENT_FROM_CHINA:
      return { text: "已达到中国", color: OrderStatusColor.SENT_FROM_CHINA };
    case OrderStatus.READY_FOR_PICKUP:
      return {
        text: "柜台",
        color: OrderStatusColor.READY_FOR_PICKUP,
      };
    case OrderStatus.ARRIVED_AT_KAZAKHSTAN:
      return {
        text: "已达到哈萨克斯坦",
        color: OrderStatusColor.ARRIVED_AT_KAZAKHSTAN,
      };
    case OrderStatus.DELIVERED:
      return { text: "已送达", color: OrderStatusColor.DELIVERED };
    case OrderStatus.BORDER:
      return { text: "报关中", color: OrderStatusColor.BORDER };
    case OrderStatus.PENDING:
      return { text: "等待中", color: OrderStatusColor.PENDING };

    case OrderStatus.SENT_FROM_KZ_INNER:
      return {
        text: "哈国内部发货中",
        color: OrderStatusColor.ARRIVED_AT_KAZAKHSTAN,
      };
    case OrderStatus.RECIEVED_FROM_KZ_INNER:
      return {
        text: "哈国分部已接货",
        color: OrderStatusColor.ARRIVED_AT_KAZAKHSTAN,
      };
    default:
      break;
  }
}

export function getOrderStatusEnumTextRus(
  text: OrderStatus | CustomerBarCodeStatus | string
) {
  switch (text) {
    case CustomerBarCodeStatus.UNREGISTERED:
      return {
        text: "Код еще не зарегистрирован",
        color: CustomerBarCodeStatusColor.UNREGISTERED,
      };
    case OrderStatus.SENT_FROM_CHINA:
      return {
        text: "Отправлено из Китая",
        color: OrderStatusColor.SENT_FROM_CHINA,
      };
    case OrderStatus.ARRIVED_AT_KAZAKHSTAN:
      return {
        text: "Доставлено в Казахстан",
        color: OrderStatusColor.ARRIVED_AT_KAZAKHSTAN,
      };
    case OrderStatus.SENT_FROM_KZ_INNER:
      return {
        text: "Отправлено в филиал",
        color: OrderStatusColor.ARRIVED_AT_KAZAKHSTAN,
      };
    case OrderStatus.RECIEVED_FROM_KZ_INNER:
      return {
        text: "Прибыл в филиал",
        color: OrderStatusColor.ARRIVED_AT_KAZAKHSTAN,
      };
    case OrderStatus.READY_FOR_PICKUP:
      return {
        text: "Готов забрать",
        color: OrderStatusColor.DELIVERED,
      };
    case OrderStatus.DELIVERED:
      return { text: "Доставлено", color: OrderStatusColor.DELIVERED };
    case OrderStatus.BORDER:
      return {
        text: "В таможенной декларации",
        color: OrderStatusColor.BORDER,
      };
    case OrderStatus.PENDING:
      return { text: "В ожидании", color: OrderStatusColor.PENDING };
    default:
      break;
  }
}

export function getCabinetOrderStatusEnumTextRus(
  text: CABINET_ORDER_STATAUS | string
) {
  switch (text) {
    case CABINET_ORDER_STATAUS.WAITTING:
      return {
        text: "В ожидании",
        color: "white",
        bg: "grey",
        border: "1px solid #f8f8f8",
      };
    case CABINET_ORDER_STATAUS.IN_CHINA:
      return {
        text: "Отправлены из Китая",
        color: "#d4380d",
        bg: "#fff2e8",
        border: "1px solid #ffbb96",
      };
    case CABINET_ORDER_STATAUS.IN_KAZAKHSTAN:
      return {
        text: "Прибыли в Казахстан",
        color: "#08979c",
        bg: "#e6fffb",
        border: "1px solid #87e8de",
      };
    case CABINET_ORDER_STATAUS.DELIVERED:
      return {
        text: "Доставлены",
        color: "#389e0d",
        bg: "#f6ffed",
        border: "1px solid #b7eb8f",
      };

    default:
      break;
  }
}
