import React from "react";
import hero from "../../../assets/hero.png";
import "./style.css";
import { scroll } from "framer-motion";

const Banner = () => {
  return (
    // <div className="" style={{ background: "#e4edf9" }}>
    <div className="banner">
      {" "}
      <img src={hero} className="hero_bg wow  zoomIn" alt="hero" />
      <div className="text">
        <h1 className="wow slideInRight">Beka525 Cargo</h1>

        <p className="wow slideInRight" data-wow-duration="1.5s">
          Қытайдан Қазақстан бойынша жүктерді тасымалдау, жеткізу қызметтер
          нарығындағы белсенді дамып келе жатқан компания.
        </p>
        <a href="https://wa.me/+77077568876" target={"_blank"}>
          <button className="wow fadeIn">Хабарлау</button>
        </a>
      </div>
    </div>
    // </div>
  );
};

export default Banner;
