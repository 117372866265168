import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { ExclamationCircleOutlined, QrcodeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  TimePicker,
} from "antd";
import OrderService from "../../../service/OrderService";
import OrderDetails from "../order-details";
import { OrderStatus } from "./statusOrder";

import { getOrderStatusEnumText } from "../../../utils/getEnumText";

import OrderGroupService from "../../../service/OrderGroupService";
import OrderTimeLine from "../order-timeline";
import { ENDPOINT } from "../../../configs/AppConfig";
import socketIOClient from "socket.io-client";
import {
  formatDate,
  openNotificationWithIcon,
} from "../../../utils/notificationUtil";
import BarcodeReader from "react-barcode-reader";
import { current } from "@reduxjs/toolkit";
import Search from "antd/lib/input/Search";
import { WarehouseChina } from "../order-details/OrderDetails";
interface DataType {
  orderData: orderType[];
  total: number;
}

interface OrderGroupDataType {
  orderGroupsData: BaseOrderType[];
  total: number;
}

interface orderType extends BaseOrderType {
  client_name: string;
  client_tel: string;
  status: OrderStatus;
  title: string;
  iin?: string;
}

interface BaseOrderType {
  _id: string;
  orders: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  status: string;
  recivedOrderCount: number;
}

function Home() {
  const [orderGroups, setOrderGroups] = useState<OrderGroupDataType>({
    orderGroupsData: [],
    total: 0,
  });
  const [openEdit, setOpenEdit] = useState({
    open: false,
    orderGroupId: "",
  });

  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [openTimeline, setOpenTimeline] = useState({
    open: false,
    orderId: "",
  });

  const [orders, setOrders] = useState<DataType>({
    orderData: [],
    total: 0,
  });

  const [orderLoading, setOrderLoading] = useState(false);
  const [orderGroupLoading, setOrderGroupLoading] = useState(true);

  const [orderExpandedRowKeys, setOrderExpandedRowKeys] = useState<string[]>(
    []
  );
  const { confirm } = Modal;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);

  const [search, setSearch] = useState("");

  const showModal = () => {
    setIsAddModalOpen(true);
  };

  const handleOk = () => {
    setIsAddModalOpen(false);
  };

  const handleCancel = () => {
    setIsAddModalOpen(false);
  };

  const orderGroupPageChangeHandle = async (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPage(page);
    setOrderGroupLoading(true);
    const res = await OrderGroupService.fetchOrderGroup(
      page,
      pageSize,
      false,
      search
    );
    setOrderGroups({
      orderGroupsData: res.data.orderGroups,
      total: res.data.total,
    });
    setOrderGroupLoading(false);
  };

  const orderPageChangeHandle = async (page: number, pageSize: number) => {
    setOrderLoading(true);
    const res = await OrderService.fetchOrder(orderExpandedRowKeys[0], page);
    setOrders({
      orderData: res.data.orders,
      total: res.data.total,
    });
    setOrderLoading(false);
  };

  const openOrderDetail = (recordId: string) => {
    setOpenEdit({ open: true, orderGroupId: recordId });
    return;
  };

  const openOrderTimeline = (record: orderType) => {
    setOpenTimeline({ open: true, orderId: record._id });
    return;
  };

  const expandedRowRender = (row: any) => {
    const columns: ColumnsType<orderType> = [
      {
        title: "条码",
        dataIndex: "barCode",
        key: "name",
        render: (text) => <b>{text}</b>,
      },

      {
        title: "状况",
        key: "status",
        dataIndex: "status",
        render: (text: string, record: orderType) => {
          const data = getOrderStatusEnumText(record.status);
          if (data) {
            return <Tag color={data.color}>{data.text}</Tag>;
          }
        },
      },
      {
        title: "IIN",
        key: "iin",
        dataIndex: "iin",
      },
      {
        title: "更多",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button type="primary" onClick={() => openOrderTimeline(record)}>
              查看
            </Button>
          </Space>
        ),
      },
    ];

    return (
      <Table
        loading={orderLoading}
        rowKey="_id"
        columns={columns}
        dataSource={orders.orderData}
        pagination={{
          pageSize: 5,
          total: orders.total,
          onChange: orderPageChangeHandle,
        }}
      />
    );
  };

  const deleteOrderGroup = async (id: string) => {
    await OrderGroupService.delete(id);
    window.location.reload();
  };

  const deleteOrderGroupConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除条码组？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteOrderGroup(id);
      },
    });
  };

  const handleScanCode = async (data: string) => {
    const response = await OrderService.search({ barCode: data });
    setOrderGroups((current) => {
      const newData = current.orderGroupsData.filter(function (value, index) {
        return value._id === response.data.orderGroup;
      });

      return {
        orderGroupsData: newData,
        total: 1,
      };
    });
  };

  const columns: ColumnsType<BaseOrderType> = [
    {
      title: "名称",
      dataIndex: "title",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "创建日期",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{formatDate(text)}</span>,
    },

    {
      title: "状况",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        const data = getOrderStatusEnumText(record.status);
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },

    {
      title: "木架费",
      dataIndex: "woodFee",
      key: "woodFee",
      // render: (text, record) => (
      //   <span>{`${record.recivedOrderCount} / ${record.orders}`}</span>
      // ),
    },
    {
      title: "包装费",
      dataIndex: "packageFee",
      key: "packageFee",
      // render: (text, record) => (
      //   <span>{`${record.recivedOrderCount} / ${record.orders}`}</span>
      // ),
    },
    {
      title: "总重量",
      dataIndex: "weight",
      key: "weight",
      // render: (text, record) => (
      //   <span>{`${record.recivedOrderCount} / ${record.orders}`}</span>
      // ),
    },
    {
      title: "客户绑定数量",
      dataIndex: ["recivedOrderCount", "orders"],
      key: "recivedOrderCount",
      render: (text, record) => (
        <span>{`${record.recivedOrderCount} / ${record.orders}`}</span>
      ),
    },
    {
      title: "更多",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => openOrderDetail(record._id)}>
            扫码
            <QrcodeOutlined />
          </Button>

          <Button
            type="primary"
            onClick={() => deleteOrderGroupConfirm(record._id)}
            danger
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchOrders = async () => {
      setOrderGroupLoading(true);
      const result = await OrderGroupService.fetchOrderGroup(
        1,
        pageSize,
        false
      );
      setOrderGroups({
        orderGroupsData: result.data.orderGroups,
        total: result.data.total,
      });
      setOrderGroupLoading(false);
    };
    fetchOrders();
  }, []);

  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   socket.on("OPEN_ORDERGROUP", (data) => {
  //     openOrderDetail(data.orderGroupId);
  //   });
  //   socket.on("BARCODE_SEARCH_SCANNED", (data) => {
  //     openOrderTimeline(data.order);
  //   });
  // }, []);

  const onExpandedRowsChange = async (expanded: any, record: any) => {
    if (expanded) {
      setOrderLoading(true);
      setOrderExpandedRowKeys([record._id]);
      const result = await OrderService.fetchOrder(record._id, 1);
      setOrders({ orderData: result.data.orders, total: result.data.total });
    } else {
      setOrderExpandedRowKeys([]);
    }
    setOrderLoading(false);
  };

  const onFinish = async (values: any) => {
    try {
      const response = await OrderGroupService.add({
        ...values,
        inKazakhstan: false,
        city: null,
      });

      openNotificationWithIcon("success", "添加成功", "");

      setOrderGroups({
        ...orderGroups,
        orderGroupsData: [
          { ...response.data, orders: 0, recivedOrderCount: 0 },
          ...orderGroups.orderGroupsData,
        ],
      });

      openOrderDetail(response.data._id);
    } catch (err: any) {
      console.warn(err);
      if (err.response?.status === 422) {
        openNotificationWithIcon("error", "已存在", "");
      }
    }
  };

  const handleOnSearch = async (val: string) => {
    setSearch(val);
    setOrderGroupLoading(true);
    const result = await OrderGroupService.fetchOrderGroup(
      1,
      pageSize,
      false,
      val
    );
    setOrderGroups({
      orderGroupsData: result.data.orderGroups,
      total: result.data.total,
    });
    setOrderGroupLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const OnChangeStatusFinish = async (values: any) => {
    try {
      // console.log({
      //   ...values,
      //   orderGroups: selectedRowKeys,
      // });

      setOrderGroupLoading(true);

      const respone = await OrderGroupService.changeMultipleStatus({
        ...values,
        orderGroups: selectedRowKeys,
      });

      await orderGroupPageChangeHandle(page, pageSize);

      setOrderGroupLoading(false);
      setIsChangeStatusModalOpen(false);
    } catch (error) {}
  };

  return (
    <Card>
      <Row
        justify="space-between"
        style={{ marginBottom: 10, position: "sticky", top: 0, zIndex: 20 }}
      >
        <div>
          <Button
            style={{ marginRight: 16 }}
            onClick={() => showModal()}
            type="primary"
          >
            添加
          </Button>
          {selectedRowKeys.length >= 1 ? (
            <Button
              onClick={() => setIsChangeStatusModalOpen(true)}
              type="dashed"
            >
              更改状态
            </Button>
          ) : null}
        </div>

        <Search
          style={{ width: "30%" }}
          placeholder="搜索条码组"
          onSearch={(val) => handleOnSearch(val)}
        />
      </Row>

      {/* <BarcodeReader
        onError={(err: any) => {
          console.log(err);
        }}
        onScan={(data: string) => {
          handleScanCode(data);
        }}
      /> */}

      <Table
        rowSelection={rowSelection}
        loading={orderGroupLoading}
        columns={columns}
        dataSource={orderGroups.orderGroupsData}
        expandable={{
          expandedRowRender,
          expandedRowKeys: orderExpandedRowKeys,
          onExpand: onExpandedRowsChange,
        }}
        rowKey="_id"
        pagination={{
          pageSize: pageSize,
          total: orderGroups.total,
          current: page,
          onChange: orderGroupPageChangeHandle,
        }}
      />
      {openEdit.open && (
        <OrderDetails openEdit={openEdit} setOpenEdit={setOpenEdit} />
      )}
      {openTimeline.open && (
        <OrderTimeLine openEdit={openTimeline} setOpenEdit={setOpenTimeline} />
      )}

      <Modal
        title="Basic Modal"
        open={isAddModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {/* <Form.Item label="Field A" hidden>
          <Input placeholder="input placeholder" name="d" />
        </Form.Item> */}
        <Form
          id="addOrderGroup"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="号码组名称" name="title">
            <Input placeholder="" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`更改状态(${selectedRowKeys.length})`}
        open={isChangeStatusModalOpen}
        // onOk={handleOk}
        onCancel={() => setIsChangeStatusModalOpen(false)}
        footer={null}
      >
        {/* <Form.Item label="Field A" hidden>
          <Input placeholder="input placeholder" name="d" />
        </Form.Item> */}
        <Form
          id="changeStatusOrderGroup"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={OnChangeStatusFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="状态" name="status">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="请选测状态"
              optionFilterProp="children"
              value={OrderStatus.SENT_FROM_CHINA}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Select.Option
                key={OrderStatus.SENT_FROM_CHINA}
                value={OrderStatus.SENT_FROM_CHINA}
              >
                中国发货
              </Select.Option>
              <Select.Option
                key={OrderStatus.BORDER}
                value={OrderStatus.BORDER}
              >
                报关
              </Select.Option>
              <Select.Option
                key={OrderStatus.ARRIVED_AT_KAZAKHSTAN}
                value={OrderStatus.ARRIVED_AT_KAZAKHSTAN}
              >
                哈国接货
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="仓库" name="warehouse">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="请选测仓库"
              optionFilterProp="children"
              // value={wareHouseModal.warehouseSelected}
              // onChange={(value) => {
              //   setWareHouseModal({
              //     ...wareHouseModal,
              //     warehouseSelected: value,
              //   });
              // }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Select.Option
                key={WarehouseChina.GUANZHOU}
                value={WarehouseChina.GUANZHOU}
              >
                广州
              </Select.Option>
              <Select.Option
                key={WarehouseChina.YIWU}
                value={WarehouseChina.YIWU}
              >
                义乌
              </Select.Option>
              <Select.Option
                key={WarehouseChina.URUMQI}
                value={WarehouseChina.URUMQI}
              >
                乌鲁木齐
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="日期 / Дата"
            name="actionDate"
            rules={[{ required: true, message: "请选择!" }]}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default Home;
