import { Button, Card, Checkbox, Form, Input, Modal, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ConfirmModal from "../../../components/confirmModal";

import Cargo from "../../../img/cargo.jpg";
import {
  register,
  selectLoading,
  selectRedirect,
  selectToken,
  selectVerified,
  showLoading,
} from "../../../redux/slices/customerSlice";
import CityService from "../../../service/CityService";
interface ICityList {
  cities: ICity[];
  currentPage: number;
  total: number;
}
interface ICity {
  _id: string;
  titleRU: string;
  titleKZ: string;
  titleCN: string;
  shortTitle: string;
}

function PhoneConfirm() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const [form] = Form.useForm();
  const [cities, setCitites] = useState<ICityList>({
    cities: [],
    currentPage: 1,
    total: 0,
  });
  // const redirect_cabinet = useSelector(selectRedirect);
  // const verfied = useSelector(selectVerified);
  const token = useSelector(selectToken);

  let history = useNavigate();

  useEffect(() => {
    if (token !== "") {
      history("/cabinet");
    }
  }, [token]);

  useEffect(() => {
    document.body.classList.add("background-phone-register");
  }, []);

  useEffect(() => {
    async function fetchCitits() {
      const res = await CityService.fetchFilter(1, 100);

      setCitites(res.data);
    }
    fetchCitits();
  }, []);

  const OnRegister = (values: any) => {
    dispatch(register(values));
  };

  return (
    <div className="phoneConfirm">
      <Card>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={"/cargo.png"}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            alt="Cargo"
            width={125}
          />
        </div>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={OnRegister}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Номер телефон"
            name="telephone"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите свой номер телефона!",
              },
            ]}
          >
            <MaskedInput
              mask={
                //  https://imask.js.org/guide.html#masked-pattern
                "+7(000)000-00-00"
              }
            />
          </Form.Item>

          <Form.Item
            label="Имя на английском языке"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите свое имя на английском языке!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"Пароль"}
            name="password"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите пароль!",
                min: 6,
              },
            ]}
          >
            <Input.Password className="login_input" />
          </Form.Item>
          <Form.Item
            label={"Регион"}
            name="city"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите пароль!",
                min: 6,
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              // defaultValue="lucy"
              // style={{ width: 120 }}

              options={cities.cities.map((item: ICity) => ({
                label: item.titleKZ,
                value: item._id,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Button type="link" htmlType="button" style={{ marginRight: 10 }}>
              <Link to={"/checkin"}>Уже зарегистрировались</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Зарегистрировать
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {/* <ConfirmModal /> */}
      {/* {verfied === false && <Navigate replace to={"/checkin"} />} */}
    </div>
  );
}

export default PhoneConfirm;
