export const APP_NAME = "Emilus";

// export const SOCKET_URL = env.SOCKET_URL
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

// export const ENDPOINT = "http://localhost:5000/";
// export const API_BASE_URL = "http://localhost:5000/api";
// export const API_IMG_URL = "http://localhost:5000/api/img/";
// export const API_IMG_NO_OWNER_URL = "http://localhost:5000/api/img-noowner/";
// export const API_IMG_PRODUCT = "http://localhost:5000/api/img-photoProduct/";
// export const API_IMG_BANNER = "http://localhost:5000/api/img-banner/";
export const FILIAL_LOCAL_STORAGE_KEY = "FILIAL_ORDERS";

export const API_BASE_URL = "https://api.beka525kargo.com/api";
export const ENDPOINT = "https://api.beka525kargo.com/";
export const API_IMG_URL = "https://api.beka525kargo.com/api/img/";
export const API_IMG_NO_OWNER_URL =
  "https://api.beka525kargo.com/api/img-noowner/";
export const API_IMG_PRODUCT =
  "https://api.beka525kargo.com/api/img-photoProduct/";
export const API_IMG_BANNER = "https://api.beka525kargo.com/api/img-banner/";
