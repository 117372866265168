import { Divider, Empty, Input, Spin } from "antd";
import React, { useState } from "react";
import Cargo from "../../../img/cargo.jpg";
import OrderService from "../../../service/OrderService";
import { TimelineInterface } from "../order-details/OrderDetails";
import TimelineOrder from "../order-details/TimelineOrder";
import "../style.css";
import Header from "../../../components/main-page/Header";
import Banner from "../../../components/main-page/Banner";
import CheckOrderTab from "../../../components/main-page/CheckOrderTab";
import Price from "../../../components/main-page/Price";
import Footer from "../../../components/main-page/Footer";
import ReactWOW from "react-wow";
import ChinaAddress from "../../../components/main-page/ChinaAddress/ChinaAddress";

const { Search } = Input;
function CheckOrder() {
  return (
    <>
      <Header />
      <div className="main_container" style={{ justifyContent: "flex-start" }}>
        <Banner />
        <CheckOrderTab />
        {/* <Price /> */}
        {/* 

        <ChinaAddress /> */}

        {/* <img src={Cargo} alt="" width={400} /> */}
      </div>
      <Footer />
    </>
  );
}

export default CheckOrder;
