import { Button, Card, Input, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  getCabinetOrderStatusEnumTextRus,
  getOrderStatusEnumTextRus,
} from "../../../../utils/getEnumText";
import { CABINET_ORDER_STATAUS } from "../ICabinet";
import { RightCircleOutlined, SearchOutlined } from "@ant-design/icons";
import CustomerService from "../../../../service/CustomerService";
import CabinetService from "../../../../service/CabinetService";
import { OrderStatus } from "../../home/statusOrder";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import { selectId } from "../../../../redux/slices/customerSlice";
import { Link, useParams } from "react-router-dom";
import { IBarCodesList } from "./CabinetDataTable";
import AddNewBarCode from "./add-new-barcode";
import { count } from "console";

const CabinetStatus = () => {
  const [statusList, setStatusList] = useState([
    {
      status: ["WAITTING"],
      label: "В ожидании",
      count: 0,

      show: true,
    },
    {
      status: ["PENDING"],
      label: "На складе (Китай)",
      count: 0,

      show: true,
    },
    {
      status: ["SENT_FROM_CHINA"],
      label: "В пути",
      count: 0,

      show: true,
    },
    {
      status: ["BORDER"],
      label: "В таможенной декларации",
      count: 0,

      show: true,
    },
    {
      status: ["ARRIVED_AT_KAZAKHSTAN"],
      label: "Сортируется (Алматы)",
      count: 0,

      show: true,
    },
    {
      status: ["SENT_FROM_KZ_INNER"],
      label: "Отправлено (филиал)",
      count: 0,

      show: true,
    },
    {
      status: ["RECIEVED_FROM_KZ_INNER"],
      label: "В филиале",
      count: 0,

      show: true,
    },
    {
      status: ["READY_FOR_PICKUP"],
      label: "Готов забрать",
      count: 0,

      show: true,
    },

    {
      status: ["DELIVERED"],
      label: "Доставлен",
      count: 0,

      show: true,
    },
  ]);

  // const customerId = useSelector(selectId);
  let params = useParams();

  useEffect(() => {
    let type = params.barCodeType || "";
    // async function fetchOrderStatus(type: string) {
    //   const res = await CabinetService.getStatusCount(type);

    //   setCabinetStatusInfo(res.data);
    // }

    async function sendBarcodeStatus() {
      try {
        const result = await CabinetService.getStatusCount();

        // Create a map for faster lookups based on status id
        const statusMap = new Map(
          result.data.map((item: any) => [item._id, item.count])
        );

        // Update the statusList based on the result
        setStatusList((current: any) =>
          current.map((item: any) => {
            return {
              ...item,
              count: statusMap.get(item.status[0]) || 0,
              // Assuming status is an array and you want the count for the first status
            };
          })
        );
      } catch (error) {
        // Handle errors here
        console.error(error);
      }
    }

    sendBarcodeStatus();
  }, []);

  // const getStatusCount = (status: OrderStatus | string | null) => {
  //   const res = cabinetStatusInfo.find((el) => el._id[0] === status);
  //   if (!res) {
  //     return { _id: [], count: 0 };
  //   }
  //   return res;
  // };

  // const corresponStatus = (status: CABINET_ORDER_STATAUS | string | null) => {
  //   if (CABINET_ORDER_STATAUS.IN_KAZAKHSTAN === status) {
  //     return getStatusCount(OrderStatus.ARRIVED_AT_KAZAKHSTAN)?.count;
  //   } else if (CABINET_ORDER_STATAUS.DELIVERED === status) {
  //     return getStatusCount(OrderStatus.DELIVERED)?.count;
  //   } else if (CABINET_ORDER_STATAUS.IN_CHINA === status) {
  //     return (
  //       getStatusCount(OrderStatus.SENT_FROM_CHINA)?.count +
  //       getStatusCount(OrderStatus.BORDER)?.count
  //     );
  //   } else if (CABINET_ORDER_STATAUS.WAITTING === status) {
  //     return (
  //       getStatusCount(OrderStatus.PENDING)?.count + getStatusEmpty()?.count
  //     );
  //   } else {
  //     return 0;
  //   }
  // };

  // const getStatusEmpty = () => {
  //   const res = cabinetStatusInfo.find((el) => {
  //     if (el._id.length === 0) {
  //       return el;
  //     }
  //   });

  //   if (!res) {
  //     return { _id: [], count: 0 };
  //   }

  //   return res;
  // };

  // useEffect(() => {
  //   sendQuery("");
  //   // setSearchQuery("");
  // }, [params.barCodeType, customerId]);

  return (
    <div className="cabinet_status">
      {statusList.map((item) => (
        <Link
          key={item?.status.at(0) + "key"}
          to={"/cabinet/" + item?.status.at(0)}
        >
          <Card hoverable={false}>
            <Row align="middle" justify="space-between">
              <h1>{item.label}</h1>
              <RightCircleOutlined style={{ color: "grey", fontSize: 16 }} />
            </Row>
            <span style={{ color: "grey", fontSize: "10" }}>
              {item.count} заказов
            </span>
          </Card>
        </Link>
      ))}
      {/* {Object.keys(OrderStatus).map((item) => (
        <Link
          key={item + "key"}
          to={"/cabinet/" + params.barCodeType + "/" + item}
        >
          <Card hoverable={false}>
            <Row align="middle" justify="space-between">
              <h1>{getOrderStatusEnumTextRus(item)?.text}</h1>
              <RightCircleOutlined style={{ color: "grey", fontSize: 16 }} />
            </Row>
            <span style={{ color: "grey", fontSize: "10" }}>
              {corresponStatus(item) || 0} заказов
            </span>
          </Card>
        </Link>
      ))} */}
    </div>
  );
};

export default CabinetStatus;
