import { call, put } from "redux-saga/effects";
import history from "../../../history";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";
import { REGISTER_TEL } from "../../constants/Auth";
import {
  authenticated,
  closeLoading,
  register,
  registerSuccess,
  showAuthMessage,
  showLoading,
  showModal,
  signOutSuccess,
} from "../../slices/customerSlice";
import {
  signInRequest,
  signUpRequest,
  verifyRequest,
} from "../request/customerRequest";

// todo DRY

export function* handleSignUp(action: any): Generator<any, any, any> {
  try {
    const response = yield call(signUpRequest, action.payload);

    if (response.message === "OK") {
      yield put(registerSuccess(response.customer));

      if (!!response.accessToken) {
        yield put(registerSuccess(response));
        const { accessToken, customer } = response;
        const auth_token = {
          accessToken,
          ...customer,
        };
        localStorage.setItem(REGISTER_TEL, JSON.stringify(auth_token));
        yield put(authenticated(response));

        history.push("/cabinet");
        window.location.reload();
      } else {
        alert("unknown error");
      }
    }

    // yield put(showModal({ isModalShown: !response.customer.verified }));

    return;
  } catch (error: any) {
    console.warn(error);
    //yield put(showAuthMessage(error.response.data.message));
  }
}

export function* handleSignIn(action: any): Generator<any, any, any> {
  // const { email, password } = action.payload;
  try {
    yield put(showLoading());

    const response = yield call(signInRequest, action.payload);

    const { accessToken, customer } = response;

    if (!accessToken) {
      // alert(i18n.t("unknown_error"));
      yield put(closeLoading());
    }

    const auth_token = {
      accessToken,
      ...customer,
    };

    if (!!response.accessToken) {
      yield put(registerSuccess(response));
      const { accessToken, customer } = response;
      const auth_token = {
        accessToken,
        ...customer,
      };

      localStorage.setItem(REGISTER_TEL, JSON.stringify(auth_token));
      yield put(authenticated(response));

      history.push("/cabinet");
      window.location.reload();
    } else {
      alert("unknown error");
    }
    //yield put(showModal({ isModalShown: !response.data.customer.verified }));
  } catch (err: any) {
    yield put(showAuthMessage(err.response.data.message));
    yield put(closeLoading());
    if (err.response.status === 404) {
      setTimeout(function () {
        localStorage.removeItem(REGISTER_TEL);
        history.push("/register");
        window.location.reload();
      }, 1000);
    }
  }
}

export function* handleSignOut(action: any): Generator<any, any, any> {
  // const { email, password } = action.payload;
  try {
    localStorage.removeItem(REGISTER_TEL);
    yield put(signOutSuccess());
    history.push("/checkin");
    window.location.reload();
  } catch (err: any) {
    console.warn(err);
    yield put(showAuthMessage(err.response.data.message));
    yield put(closeLoading());
  }
}

export function* handleVerify(action: any): Generator<any, any, any> {
  // const { email, password } = action.payload;
  try {
    yield put(showLoading());
    const response = yield call(verifyRequest, action.payload);

    const { customer } = response;
    if (!customer) {
      openNotificationWithIcon("error", "Ошибка", "");
      yield put(closeLoading());
      return;
    }

    if (customer.verified === false) {
      openNotificationWithIcon("warning", "Не подтверждено", "");
      localStorage.removeItem(REGISTER_TEL);
      yield put(closeLoading());
      return;
    }

    if (!!response.accessToken) {
      yield put(registerSuccess(response));
      const { accessToken } = response;
      const auth_token = {
        accessToken,
        ...customer,
      };
      localStorage.setItem(REGISTER_TEL, JSON.stringify(auth_token));
      yield put(authenticated(response));
      history.push("/cabinet");
      // window.location.reload();
    } else {
      alert("Ошибка");
    }

    // openNotificationWithIcon("success", data.message, "");
  } catch (err: any) {
    console.warn(err);
    yield put(showAuthMessage(err.response.data.message));
    yield put(closeLoading());
  }
}

// export function* handleUpdateContact(action: any): Generator<any, any, any> {
//   try {
//     //const response = yield call(updateContactRequest, action.payload);
//     yield put(fillContactInfo(response));
//   } catch (error) {
//     console.warn(error);
//   }
// }
