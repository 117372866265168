import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  notification,
  Select,
  Space,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import CityService, { CargoType } from "../../../service/CityService";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function EditCity({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const keys1 = Object.keys(CargoType);

  useEffect(() => {
    const fetchCity = async () => {
      const response = await CityService.getOne(openData.CityId);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        titleRU: response.data.titleRU,
        titleKZ: response.data.titleKZ,
        titleCN: response.data.titleCN,
        isMain: response.data.isMain,
        shortTitle: response.data.shortTitle,
        insta: response.data.insta,
        tiktok: response.data.tiktok,
        phone: response.data.phone,
        whatsApp: response.data.whatsApp,
        telegram: response.data.telegram,
        price: response.data.price,
      });
      setLoading(false);
    };
    fetchCity();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, CityId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     titleRU: response.data.titleRU,
    //     titleKZ: response.data.titleKZ,
    //     isMain: response.data.isMain,

    await CityService.update(values, openData.CityId);
    openNotification("topRight");
    setLoading(false);
  };

  return (
    <Drawer
      title="修改分部"
      placement="right"
      onClose={onClose}
      size="large"
      open={openData.open}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // style={{
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          // }}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="名称KZ" name="titleKZ" required>
            <Input />
          </Form.Item>

          <Form.Item label="名称RU" name="titleRU" required>
            <Input />
          </Form.Item>

          <Form.Item label="名称CN" name="titleCN" required>
            <Input />
          </Form.Item>

          <Form.Item label="备注" name="shortTitle" required>
            <Input />
          </Form.Item>
          <Form.Item label="收价" name="price" required>
            <Input />
          </Form.Item>
          <Form.Item valuePropName="checked" label="主分部" name="isMain">
            <Checkbox>主分部</Checkbox>
          </Form.Item>

          <Form.Item label="Insta" name="insta" required>
            <Input />
          </Form.Item>

          <Form.Item label="Tiktok" name="tiktok" required>
            <Input />
          </Form.Item>

          <Form.Item label="Telegram" name="telegram" required>
            <Input />
          </Form.Item>

          <Form.List name="phone">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      {...field}
                      label="电话"
                      // name={[field.name, "price"]}
                      // rules={[{ required: true, message: "Missing price" }]}
                    >
                      <Input />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加电话
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.List name="whatsApp">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      {...field}
                      label="whatsApp"
                      // name={[field.name, "price"]}
                      // rules={[{ required: true, message: "Missing price" }]}
                    >
                      <Input />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加whatsApp
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              确认
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditCity;
