import { Button, Form, Input, Modal, notification, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CabinetService, {
  BarcodeInput,
} from "../../../../../service/CabinetService";
import CustomerService from "../../../../../service/CustomerService";
import { openNotificationWithIcon } from "../../../../../utils/notificationUtil";
import { IBarCodesList } from "../CabinetDataTable";
import { BarCodeType } from "../../../../../configs/CabinetNavigation";
import { useParams } from "react-router-dom";

function AddNewBarCode({ openData, setOpenData, setBarCodesList }: any) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  let params = useParams();

  const onFinish = async (values: BarcodeInput) => {
    setLoading(true);
    // request

    try {
      const response = await CabinetService.create(values);
      setBarCodesList((currVal: IBarCodesList) => {
        return currVal.data.length < 12
          ? {
              ...currVal,
              data: [...currVal.data, response.data],
            }
          : currVal;
      });

      openNotificationWithIcon("success", "Добавлено успешно", "");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpenData(false);
  };

  // export enum ORDER_REPORT_STATUS {
  //   ARRIVED_AT_KAZAKHSTAN = "ARRIVED_AT_KAZAKHSTAN",
  //   SENT_FROM_KZ_INNER = "SENT_FROM_KZ_INNER",
  //   RECIEVED_FROM_KZ_INNER = "RECIEVED_FROM_KZ_INNER",
  //   IN_CHINA = "IN_CHINA",
  //   // WAITTING = "WAITTING",
  // }
  return (
    <Modal
      className="phoneConfirmModal"
      footer={[
        <Button
          form="add_barcode_modal"
          key="submit"
          type="primary"
          htmlType="submit"
          // onClick={confirmPhoneHanler}
          loading={loading}
        >
          Подтвердить
        </Button>,
      ]}
      width={300}
      closable={true}
      title="Добавить штрих-код"
      open={openData}
      onCancel={onClose}
    >
      <Form
        id="add_barcode_modal"
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item label="Штрих-код" name="barCode">
          <Input />
        </Form.Item>

        <Form.Item label="Название" name="title">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddNewBarCode;
