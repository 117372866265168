import fetchCustomerApi from "../auth/PhoneInterceptor";
import { BarCodeType } from "../configs/CabinetNavigation";
import { getAuthState } from "../redux/slices/customerSlice";

const prefix = "/customer-barCode";

export interface BarcodeInput {
  barCode: string;
  type: string;
}

const authState = getAuthState();
const customerId = authState._id || "";

const CabinetService = {
  paginate: function (status: string[], page: number, limit = 12, search = "") {
    return fetchCustomerApi({
      url: `${prefix}/paginate-status?page=${page}&limit=${limit}&search=${search}`,
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: {
        status,
      },
    });
  },
  // paginate: function (status: string, page: number, limit = 12, search = "") {
  //   return fetchCustomerApi({
  //     url: `${prefix}/paginate/${status}?page=${page}&limit=${limit}&search=${search}`,
  //     method: "get",
  //     headers: {
  //       "public-request": "true",
  //     },
  //   });
  // },

  // delete: function (barCodeId: string) {
  //   return fetchCustomerApi({
  //     url: `${prefix}/${customerId}/${barCodeId}`,
  //     method: "delete",
  //     headers: {
  //       "public-request": "true",
  //     },
  //   });
  // },

  update: function (barCodeId: string) {
    return fetchCustomerApi({
      url: `${prefix}/${barCodeId}`,
      method: "put",
      headers: {
        "public-request": "true",
      },
    });
  },
  create: function (data: BarcodeInput) {
    return fetchCustomerApi({
      url: `${prefix}`,
      method: "post",
      data,
      headers: {
        "public-request": "true",
      },
    });
  },

  findOne: function (barCodeId: string) {
    return fetchCustomerApi({
      url: `${prefix}/findOne`,
      method: "post",
      data: {
        customerBarCodeId: barCodeId,
      },
      headers: {
        "public-request": "true",
      },
    });
  },

  getStatusCount: function () {
    return fetchCustomerApi({
      url: `${prefix}/status-order-count`,
      // url: `${prefix}-status/${type}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default CabinetService;
