import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./views/auth-views/login";
import "antd/dist/antd.css";
import CheckOrder from "./views/app-views/checkOrder";
// import Calculator from "./views/app-views/calculator";
import AppView from "./views/app-views";
// import PhoneConfirm from "./views/app-views/phoneConfirm";
// import Cabinet from "./views/app-views/cabinet";
// import CheckIn from "./views/app-views/checkIn";
import { useSelector } from "react-redux";
import { selectRedirect } from "./redux/slices/customerSlice";
import ForgetPassword from "./views/auth-views/forget/ForgetPassword";
import Filial from "./views/app-views/filial";
import Cabinet from "./views/app-views/cabinet";
import CheckIn from "./views/app-views/checkIn";
import PhoneConfirm from "./views/app-views/phoneConfirm";
// import WOW from "wowjs";

function App() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.WOW = require("wowjs");
    }
    new window.WOW.WOW({ live: false }).init();
  }, []);
  return (
    <>
      <Routes>
        {/* <Route path="login" element={<Login />} />
        <Route path="register" element={<PhoneConfirm />} />
        <Route path="cabinet/*" element={<Cabinet />} />
        <Route path="checkin" element={<CheckIn />} />
        <Route path="forgetpassword" element={<ForgetPassword />} />
        <Route path="cal" element={<Calculator />} /> */}
        <Route path="admin/*" element={<AppView />} />
        <Route path="login" element={<Login />} />

        <Route path="register" element={<PhoneConfirm />} />
        <Route path="cabinet/*" element={<Cabinet />} />
        <Route path="checkin" element={<CheckIn />} />
        <Route path="forgetpassword" element={<ForgetPassword />} />
        <Route index element={<CheckOrder />} />
      </Routes>
      {/* */}
    </>
  );
}

export default App;
